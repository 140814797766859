import { type Router } from 'next/router'

import * as Sentry from '@sentry/nextjs'
import Cookies from 'js-cookie'
import { useCookies } from 'react-cookie'

export function useBuildCookie(
	cookieName: string,
	cookieDuration: number,
	asPath: Router['asPath']
) {
	const [cookies, setCookie] = useCookies([cookieName, '__utmz'])

	const urlParams = asPath.split('?')[1]
	const urlSearchParams = new URLSearchParams(urlParams)
	const params = Object.fromEntries(urlSearchParams.entries())

	if (cookies[cookieName]) {
		return null
	}

	try {
		setCookie(cookieName, buildAttributes(params), {
			path: '/',
			expires: getCookieExpiration(cookieDuration)
		})
	} catch (err) {
		if (err instanceof Error) {
			Sentry.captureException(
				new Error(`Error setting ${cookieName} } cookie. ${err.message}`, { cause: err })
			)
		}
		console.error(err)
	}
}

type QueryParams = {
	[key: string]: string
}
function buildAttributes(queryParams: QueryParams) {
	// Loop through all of the first class citizens trying to get the value for property
	return Object.entries(trackingOptions).reduce((acc, [key, value]) => {
		return { ...acc, [key]: getTrackingData(value, queryParams) }
	}, {})
}

// Each trackingOptions.{key} will be a property on the attribution cookie.
const trackingOptions = {
	source: {
		queryKeys: ['ga_source', 'utm_source'],
		cookieKey: 'utmcsr',
		defaultValue: '(direct)'
	},
	medium: {
		queryKeys: ['ga_medium', 'utm_medium'],
		cookieKey: 'utmcmd',
		defaultValue: '(none)'
	},
	campaign: {
		queryKeys: ['ga_campaign', 'utm_campaign'],
		cookieKey: 'utmccn',
		defaultValue: '(none)'
	},
	keyword: {
		queryKeys: ['ga_query', 'ga_term', 'utm_term'],
		cookieKey: 'utmctr',
		defaultValue: '(none)'
	},
	adgroup: {
		queryKeys: ['ga_content', 'utm_content'],
		cookieKey: 'utmcct',
		defaultValue: '(none)'
	}
}

type TrackingOptions = (typeof trackingOptions)[keyof typeof trackingOptions]
function getTrackingData(options: TrackingOptions, queryParams: QueryParams) {
	let queryKeys = options.queryKeys || []

	const value = queryKeys.find((queryValue) => queryValue in queryParams)
	if (value) return queryParams[value]

	// If the key couldn't be found in the URL, look for the key in the __utmz cookie.
	if (options.cookieKey) {
		const cookieInfo = utmzParser()

		if (cookieInfo && options.cookieKey in cookieInfo) {
			return cookieInfo[options.cookieKey]
		}
	}

	// If the key couldn't be found in the URL or the __utmz cookie, try to set a default value
	if (options.defaultValue) {
		return options.defaultValue
	}

	return null
}

function getCookieExpiration(days: number) {
	if (days === 0) return undefined

	const date = new Date()

	date.setDate(date.getDate() + days)

	return date
}

function utmzParser() {
	const utmzCookie = Cookies.get('__utmz')
	if (!utmzCookie) return {}

	// Split on a character (not a period or pipe), one or more times, that is followed by a equal sign followed
	// by any character, one or more times, that is not a pipe.
	// E.G. '41658941.1397145795.1.1.utmcsr=duckduckgo.com|utmccn=(referral)|utmcmd=referral|utmcct=/'
	// will be ["41658941.1397145795.1.1.", "utmcsr=duckduckgo.com", "|", "utmccn=(referral)", "|", "utmcmd=referral", "|", "utmcct=/", ""]
	var pieces = utmzCookie.split(/([^.|]*=[^|]*)/)

	const utmz = pieces.reduce<{ [key: string]: string }>((acc, piece) => {
		const matches = piece.split('=')

		if (matches.length === 2) {
			return { ...acc, [matches[0]]: matches[1] }
		}

		return acc
	}, {})

	return utmz
}
