import { useEffect } from 'react'

import { useRouter } from 'next/router'
import Script from 'next/script'

import { sendDataLayerEvent } from '@/utils/analytics/events'

import { useAnalyticsContext } from '@/components/Scripts/Segment/AnalyticsProvider'

import { useConsentContext } from './ConsentProvider'
import { getLocalizedScriptId } from './config'

export const OneTrust = () => {
	const { setOnetrustActiveGroups } = useConsentContext()
	const { analytics } = useAnalyticsContext()
	const { locale } = useRouter()

	const domainScript = getLocalizedScriptId(locale)

	useEffect(() => {
		window.OptanonWrapper = () => {
			// Get initial Consent Provider state
			let onetrustActiveGroups: string = window.OnetrustActiveGroups ?? ''
			setOnetrustActiveGroups(onetrustActiveGroups)

			window.OneTrust?.OnConsentChanged(() => {
				// Update Consent Provider state
				if (window.OnetrustActiveGroups && window.OnetrustActiveGroups !== '')
					setOnetrustActiveGroups(onetrustActiveGroups)

				// Send OneTrustGroupsUpdated data layer event
				sendDataLayerEvent('OneTrustGroupsUpdated', {})

				// Fire an events passing users tracking preferences and locale
				window.analytics?.track('Consent Preferences Saved', {
					url: window.location.href,
					path: window.location.pathname,
					title: document.title,
					referrer: document.referrer,
					search: window.location.search,
					onetrustActiveGroups: window.OnetrustActiveGroups
				})
			})
		}
	}, [analytics, setOnetrustActiveGroups])

	return (
		<Script
			id="onetrust-script"
			strategy="afterInteractive"
			src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
			data-domain-script={domainScript}
		/>
	)
}
