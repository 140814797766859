/** @type {import('next-translate').I18nConfig} */
module.exports = {
	locales: [
		'en-US',
		'en-AU',
		'nl-NL',
		'en-GB',
		'fr-FR',
		'it-IT',
		'es-ES',
		'es-MX',
		'de-DE',
		'nb-NO',
		'da-DK',
		'de-AT',
		'sv-SE',
		'en-SG',
		'en-ES',
		'en-MX',
		'en-FR',
		'en-IT',
		'en-NL',
		'en-DE',
		'en-AT',
		'en-DK',
		'en-SE',
		'en-NO',
		'en-IN',
		'pl-PL',
		'en-ZA',
		'en-AE',
		'ro-RO'
	],
	loadLocaleFrom: (lang, ns) =>
		import(`./src/config/locales/${lang}/${ns}.json`).then((m) => m.default),
	defaultLocale: process.env.DEV_LOCALE || 'en-US',
	defaultNS: 'trials',
	logBuild: false,
	localeDetection: false,
	pages: {
		'*': ['trials', 'pricing', 'caseStudyDefaultSEO']
	}
}
