import { useEffect } from 'react'

/*
	Adds the Vercel Preview Deployment URL to the window object
	Used for Playwright tests as we don't have access to the process object there
*/
export const useVercelEnvs = () => {
	useEffect(() => {
		// Adds Vercel URL to window object
		if (!window?.NEXT_PUBLIC_VERCEL_URL && process.env.NEXT_PUBLIC_VERCEL_URL) {
			const vercelUrl = process.env.NEXT_PUBLIC_VERCEL_URL
			const isLocalhost = vercelUrl.includes('localhost')
			const hasProtocol = vercelUrl.includes('http://') || vercelUrl.includes('https://')

			if (hasProtocol) {
				window.NEXT_PUBLIC_VERCEL_URL = `${process.env.NEXT_PUBLIC_VERCEL_URL}`
			}
			if (!hasProtocol && isLocalhost) {
				window.NEXT_PUBLIC_VERCEL_URL = `http://${process.env.NEXT_PUBLIC_VERCEL_URL}`
			}
			if (!hasProtocol && !isLocalhost) {
				window.NEXT_PUBLIC_VERCEL_URL = `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
			}
		}
		// Adds PORT to window object
		if (!window?.PORT && process.env.NEXT_PUBLIC_PORT) {
			window.PORT = Number(process.env.NEXT_PUBLIC_PORT)
		}
	}, [])
}
