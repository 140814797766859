import * as Sentry from '@sentry/nextjs'

export function sendDataLayerEvent(eventName: string, data: { [key: string]: unknown }) {
	try {
		if (!window.dataLayer) window.dataLayer = []

		window.dataLayer.push({ event: eventName, ...data })
	} catch (error) {
		Sentry.captureException(new Error('Error sending Data Layer event', { cause: error }))
		console.error(error)
	}
}
