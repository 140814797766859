import {
	Dispatch,
	ReactNode,
	SetStateAction,
	createContext,
	useContext,
	useEffect,
	useState
} from 'react'

import Cookies from 'js-cookie'

interface ConsentContextValue {
	onetrustActiveGroups: string | any
	setOnetrustActiveGroups: Dispatch<SetStateAction<string | any>>
	strictlyNecessary: boolean | any
	setStrictlyNecessary: Dispatch<SetStateAction<boolean | any>>
	functional: boolean | any
	setFunctional: Dispatch<SetStateAction<boolean | any>>
	performance: boolean | any
	setPerformance: Dispatch<SetStateAction<boolean | any>>
	targeting: boolean | any
	setTargeting: Dispatch<SetStateAction<boolean | any>>
}

const ConsentContext = createContext<ConsentContextValue | undefined>(undefined)

export function useConsentContext() {
	const consentContext = useContext(ConsentContext)

	if (consentContext === undefined) {
		throw new Error('useConsentContext must be used within a ConsentProvider')
	}

	return consentContext
}

export function getPrefsFromCookie(category?: string | undefined) {
	const consentCookie = Cookies.get('OptanonConsent')
	const segmentConsentCookie = Cookies.get('tracking-preferences')

	if (typeof consentCookie === 'string') {
		switch (category) {
			case 'strictlyNecessary':
				return consentCookie.indexOf('C0001:1') >= 0
			case 'functional':
				return consentCookie.indexOf('C0003:1') >= 0
			case 'performance':
				return consentCookie.indexOf('C0002:1') >= 0
			case 'targeting':
				return consentCookie.indexOf('C0004:1') >= 0
			default:
				return false
		}
	} else if (typeof segmentConsentCookie === 'string') {
		const segmentConsentCookieJson = JSON.parse(segmentConsentCookie)

		switch (category) {
			case 'functional':
				return segmentConsentCookieJson?.custom['Functional'] === true
			case 'performance':
				return segmentConsentCookieJson?.custom['Analytics'] === true
			case 'targeting':
				return segmentConsentCookieJson?.custom['Targeting and Advertising'] === true
			default:
				return false
		}
	} else {
		return false
	}
}

export function ConsentProvider({ children }: { children: ReactNode }) {
	const [onetrustActiveGroups, setOnetrustActiveGroups] = useState('')

	// Try to set initial preferences from OptanonConsent cookie before OneTrust JS loads
	const [strictlyNecessary, setStrictlyNecessary] = useState(
		getPrefsFromCookie('strictlyNecessary')
	)
	const [functional, setFunctional] = useState(getPrefsFromCookie('functional'))
	const [performance, setPerformance] = useState(getPrefsFromCookie('performance'))
	const [targeting, setTargeting] = useState(getPrefsFromCookie('targeting'))

	useEffect(() => {
		if (onetrustActiveGroups.length > 0) {
			// Setting consent value coming from OneTrust
			const currentOnetrustActiveGroups: string = onetrustActiveGroups
			const currentStrictlyNecessary: boolean = true // Always force this to be true
			const currentFunctional: boolean = onetrustActiveGroups?.toString().indexOf('C0003') >= 0
			const currentPerformance: boolean = onetrustActiveGroups?.toString().indexOf('C0002') >= 0
			const currentTargeting: boolean = onetrustActiveGroups?.toString().indexOf('C0004') >= 0

			// Listent for dataLayer event and update state if values change
			if (currentOnetrustActiveGroups !== onetrustActiveGroups)
				setOnetrustActiveGroups(currentOnetrustActiveGroups)
			if (currentStrictlyNecessary !== strictlyNecessary)
				setStrictlyNecessary(currentStrictlyNecessary)
			if (currentFunctional !== functional) setFunctional(currentFunctional)
			if (currentPerformance !== performance) setPerformance(currentPerformance)
			if (currentTargeting !== targeting) setTargeting(currentTargeting)
		}
	}, [onetrustActiveGroups, strictlyNecessary, functional, performance, targeting])

	// return consent
	return (
		<ConsentContext.Provider
			value={{
				onetrustActiveGroups,
				setOnetrustActiveGroups,
				strictlyNecessary,
				setStrictlyNecessary,
				functional,
				setFunctional,
				performance,
				setPerformance,
				targeting,
				setTargeting
			}}
		>
			{children}
		</ConsentContext.Provider>
	)
}
