import { Analytics, GroupTraits, ID, UserTraits } from '@segment/analytics-next'

import { driftFastlaneCaptureFields } from './config'
import { pickData } from './utils'

export function handleDriftFastlane(fastlaneData: Record<string, string>, driftPlaybookId: number) {
	try {
		if (typeof window.drift === 'undefined') {
			throw new Error('Drift handleDriftFastlane Error - window.drift is not defined')
		}

		if (!driftPlaybookId) {
			throw new Error('Drift handleDriftFastlane Error - driftPlaybookId is required')
		}

		// Only send to data to Drift if API is defined and campaignId is a number.
		if (fastlaneData && typeof driftPlaybookId === 'number' && window.drift.api) {
			const driftFastlaneMapped = pickData(fastlaneData, driftFastlaneCaptureFields)

			window.drift.api.collectFormData(driftFastlaneMapped, {
				campaignId: driftPlaybookId
			})
		}
	} catch (error) {
		throw new Error(`Drift handleDriftFastlane Error`)
	}
}

export function sendDriftEvent(eventName: string, data: any) {
	if (typeof window.drift === 'undefined') {
		throw new Error('Drift Track Error - window.drift is not defined')
	}
	// Fire Drift Event
	try {
		window.drift.track(eventName, { path: window.location.pathname })
	} catch (error) {
		throw new Error(`Drift Track Error - ${eventName}`)
	}
}

type DriftAttributes = {
	segment_anonymous_id?: ID
	email?: UserTraits['email']
	first_name?: UserTraits['firstName']
	last_name?: UserTraits['lastName']
	company?: UserTraits['company']
	phone?: UserTraits['phone']
	projected_annual_revenue?: UserTraits['projectedAnnualRevenue']
	storeId?: UserTraits['storeId']
	country?: UserTraits['country']
	state?: UserTraits['state']
	store_plan?: GroupTraits['plan']
	store_sku?: GroupTraits['sku']
}

export function sendDriftUserAttributes(analytics: Analytics) {
	if (typeof window.drift === 'undefined') {
		throw new Error('Send Drift User Attributes Error - window.drift is not defined')
	} else if (!window.drift.api) {
		throw new Error('Send Drift User Attributes Error - window.drift.api is not defined')
	}

	try {
		// Build Drift Identify Data object
		const userAttributes: DriftAttributes = {}
		let user = analytics.user()
		let traits = user?.traits()
		if (user?.anonymousId()) userAttributes.segment_anonymous_id = user.anonymousId()
		if (traits?.email) userAttributes.email = traits?.email
		if (traits?.firstName) userAttributes.first_name = traits?.firstName
		if (traits?.lastName) userAttributes.last_name = traits?.lastName
		if (traits?.company) userAttributes.company = traits?.company
		if (traits?.phone) userAttributes.phone = traits?.phone
		if (traits?.projectedAnnualRevenue)
			userAttributes.projected_annual_revenue = traits?.projectedAnnualRevenue
		if (traits?.storeId) userAttributes.storeId = traits?.storeId
		if (traits?.country) userAttributes.country = traits?.country
		if (traits?.state) userAttributes.state = traits?.state
		if (analytics.group().traits()?.plan)
			userAttributes.store_plan = analytics.group().traits()?.plan
		if (analytics.group().traits()?.sku) userAttributes.store_sku = analytics.group().traits()?.sku
		window.drift.api.setUserAttributes(userAttributes)
	} catch (error) {
		throw new Error('Error sending Drift user attributes')
	}
}
