import { useEffect, useState } from 'react'

import { useRouter } from 'next/router'
import Script from 'next/script'

import Cookies from 'js-cookie'

import { useConsentContext } from '@/components/Scripts/OneTrust/ConsentProvider'

export function Munchkin() {
	const router = useRouter()
	let { functional } = useConsentContext()
	const [loadingState, setLoadingState] = useState('pending-consent')

	if (loadingState === 'pending-consent' && functional) setLoadingState('ready')

	// Fire Munchkin page event on route change
	useEffect(() => {
		const handleRouteChange = () => {
			const options: { url: string; params?: string } = {
				url: window.location.pathname
			}
			if (window.location.search) options['params'] = window.location.search

			window?.Munchkin?.munchkinFunction('visitWebPage', options)
		}

		if (!router.isReady || !window.drift) return

		router.events.on('routeChangeComplete', handleRouteChange)
		handleRouteChange()

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [router])

	// Set mkto_opt_out cookie when user opts out of functional category
	useEffect(() => {
		if (functional) {
			// Remove the cookie if user accepts the function category and cookie exists
			if (Cookies.get('mkto_opt_out')) Cookies.remove('mkto_opt_out')
		} else {
			const date = new Date()
			const time = date.getTime()
			const expTime = time + 3600 * 1000 * 87660

			date.setTime(expTime)
			Cookies.set('mkto_opt_out', 'true', {
				expires: date,
				path: '/'
			})
		}
	}, [functional])

	const getHostnameLevels = () => {
		const hostname = window.location.hostname
		const hostnameLevels = hostname.split('.')
		if (hostnameLevels[0] === 'www') {
			hostnameLevels.shift()
		}
		return hostnameLevels.length
	}

	// Initialize Munchkin once the script is loaded
	useEffect(() => {
		if (loadingState === 'complete') {
			setLoadingState('initialized')
			window?.Munchkin?.init(process.env.NEXT_PUBLIC_MARKETO_INSTANCE_ID, {
				apiOnly: true,
				asyncOnly: true,
				disableClickDelay: true,
				domainLevel: getHostnameLevels(),
				domainSelectorV2: true
			})
		}
	}, [loadingState, setLoadingState])

	if (loadingState !== 'pending-consent') {
		if (loadingState === 'ready') setLoadingState('loaded')

		return (
			<Script
				id="munchkin-script"
				strategy="lazyOnload"
				src="//munchkin.marketo.net/munchkin.js"
				onLoad={() => {
					if (loadingState === 'loaded') setLoadingState('complete')
				}}
			/>
		)
	} else {
		return ''
	}
}
