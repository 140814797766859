export const AMPLITUDE_TRACKING = 'Amplitude Experiment'

export enum TreatmentEnum {
	ON = 'on',
	OFF = 'off',
	CONTROL = 'control',
	CONTROL2 = 'control-2',
	TREATMENT = 'treatment',
	TREATMENT1 = 'treatment-1',
	TREATMENT2 = 'treatment-2',
	TREATMENT3 = 'treatment-3',
	EXCLUDED = 'excluded'
}

export enum CookiesEnum {
	AmplitudeExperimentation = 'bc_amplitude_experimentation'
}

export enum StatusEnum {
	RUNNING = 'running'
}

export enum VisibilityEnum {
	Visible = 'visible',
	Invisible = 'invisible'
}

export enum SourceEnum {
	MarketingWebsites = 'marketing-websites'
}

export enum TypeEnum {
	ExperimentOrPersonalization = 'Experiment or Personalization'
}
