/**
 * Locale for SEO OG:LOCALE needs to be in the format: xx_XX
 * Locale for Norway is nb-NO, but for SEO we need it to be no_NO
 */
export function getSeoLocale(locale = 'en-US') {
	if (locale === 'x-default') {
		return locale
	}
	return locale.replace('-', '_').replace('nb', 'no')
}
