// 10 minute timeout for polling store status
export const TRIAL_TIMEOUT = 1000 * 60 * 10

// 5 second interval in between store status calls
export const TRIAL_INTERVAL = 5000

// 5 second wait before redirecting to SAML
export const REDIRECT_TIMEOUT = 5000

export const LOADING_MESSAGE_TIMEOUT = 30000

export const LONGER_THAN_EXPECTED_TIMEOUT = 100000

// List of revenues that are used to change the SKU of the trial store to enterprise
export const ENTERPRISE_REVENUES = ['$1M to $20M', '$20M to $50M', '$50M to $100M', '$100M+']

// The default SKU of a trial store
export const DEFAULT_SKU = 'STORE-TRIAL-30DAY'

// The default enterprise SKU of a trial store
export const ENTERPRISE_SKU = 'STORE-ENTERPRISE-TRIAL-SELFSERVE-30DAY'

// referral ID cookie name
export const REFERRAL_COOKIE = 'bc_ref'

export const INVALID_TOKEN_ERROR = 'token is invalid'

export const PBY_FETCH_OAUTH_LOG_MSG = 'PoweredByClient - refreshing oauth token'

// Feature flags
export const REGION_SELECTION_FEATURE_KEY = 'region_selection_feature'

// CSRF cookie names
export const CSRF_SECRET_COOKIE_NAME = '_csrf'
export const CSRF_TOKEN_COOKIE_NAME = 'csrf_token'
