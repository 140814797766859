const googleSearchConsoleMetaName = 'google-site-verification'

export const googleSearchConsoleConfigLocale = {
	'de-AT': {
		name: googleSearchConsoleMetaName,
		content: 'CboGtH7IfsbPtF05LGfWnPIXV8uVkmpHgo4Lu64TqMg',
		keyOverride: 'google-site-verification-AT'
	},

	'en-SG': {
		name: googleSearchConsoleMetaName,
		content: 'wvsOK9Pzaq9dAzVUoSDdl6j0k8XpdVCUelcnoi-KmtY',
		keyOverride: 'google-site-verification-SG'
	},
	'nb-NO': {
		name: googleSearchConsoleMetaName,
		content: 'zEtpCyL3p6mfEo9o_60AwK7fQpUOQbpvwwjo0zWhH7o',
		keyOverride: 'google-site-verification-NO'
	},
	'nl-NL': {
		name: googleSearchConsoleMetaName,
		content: 'rHTYDb1IvNtw28vJcy8E4WI5m9PXEj-ymN0Co2JXA5A',
		keyOverride: 'google-site-verification-NL'
	},
	'fr-FR': {
		name: googleSearchConsoleMetaName,
		content: 'dbrm8DsnSFgX_Y81dRGVsitAEe7WmuCshEMIWc0Xflo',
		keyOverride: 'google-site-verification-FR'
	},
	'de-DE': {
		name: googleSearchConsoleMetaName,
		content: 'I7T90bNFgNY827SbGa9PHVy6gPEOGdwT6Ya8aIg7J58',
		keyOverride: 'google-site-verification-DE'
	},
	'it-IT': {
		name: googleSearchConsoleMetaName,
		content: 'GRvseqfi_T30NCUvi0wiy0TcDDLeJCDC4NzXqwFLcsc',
		keyOverride: 'google-site-verification-IT'
	},
	'en-GB': {
		name: googleSearchConsoleMetaName,
		content: '3PC02Z0S28Q87VqpjS_YZTuFLvaqm8V04ssa31Fzhfo',
		keyOverride: 'google-site-verification-GB'
	},
	'da-DK': {
		name: googleSearchConsoleMetaName,
		content: '4czqBhS4JZfQOJq3DqJV38eG9mpgV_7z4T5gvjnswQs',
		keyOverride: 'google-site-verification-DK'
	},
	'es-MX': {
		name: googleSearchConsoleMetaName,
		content: '-3hmkhd9JoQUDJ5zrElHOoyIrhMAWsGJ8DjcLiIItEo',
		keyOverride: 'google-site-verification-MX'
	},
	'en-AU': {
		name: googleSearchConsoleMetaName,
		content: '_rp1AT6AvGjA5ucy9Mubi9bxa_J99IG8SgkXo6YARkA',
		keyOverride: 'google-site-verification-AU'
	},
	'es-ES': {
		name: googleSearchConsoleMetaName,
		content: '4ae2FaMMEoFJNmxyS3QBj2JRI4ma82s4QU89rcd0Hgg',
		keyOverride: 'google-site-verification-ES'
	},
	'sv-SE': {
		name: googleSearchConsoleMetaName,
		content: 'jZjQ499Dm186N0MVdROhW5CZr9I2y8tQgH3mDwroDTI',
		keyOverride: 'google-site-verification-SE'
	}
}
