import { useEffect } from 'react'

import Cookies from 'js-cookie'
import { REFERRAL_COOKIE } from 'src/modules/trials/constants'

type Props = {
	bcReferralValue?: string | null
}

export const useReferralCookie = ({ bcReferralValue }: Props) => {
	const bcRefCurrentValue = Cookies.get(REFERRAL_COOKIE)

	useEffect(() => {
		if (bcRefCurrentValue) return
		// Setting Referral value coming from makeswift
		else if (bcReferralValue) {
			Cookies.set(REFERRAL_COOKIE, bcReferralValue)
		}
	}, [bcRefCurrentValue, bcReferralValue]) // Only run on mount and when `bcReferralValue` and `bcRefCurrentValue` changes
}
