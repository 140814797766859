interface CaptureFields {
	name: string
	value: string
}

export const pickData = (values: Record<string, string>, fields: CaptureFields[]) => {
	return fields.reduce((current, element) => {
		const newElement = { [element.name]: values[element.value] }

		return {
			...current,
			...newElement
		}
	}, {})
}
