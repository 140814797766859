export enum LocaleTypes {
	en_US = 'en-US',
	en_GB = 'en-GB',
	en_AU = 'en-AU',
	en_SG = 'en-SG',
	da_DK = 'da-DK',
	de_DE = 'de-DE',
	de_AT = 'de-AT',
	es_ES = 'es-ES',
	es_LA = 'es-LA',
	es_MX = 'es-MX',
	fr_FR = 'fr-FR',
	it_IT = 'it-IT',
	nb_NO = 'nb-NO',
	nl_NL = 'nl-NL',
	sv_SE = 'sv-SE'
}
