import { AnalyticsBrowser } from '@segment/analytics-next'

export interface PageProps {
	url: string
	hostname: string
	referrer: string
	path: string
	search: string
}

export interface WebVitalsEvent {
	id: string
	name: string
	delta: number
	deltaRounded: number
	navigationType: string
	rating: string
	value: number
	valueRounded: number
}

export const trackWebVitals = (
	analytics: AnalyticsBrowser,
	pageProps: PageProps,
	eventDetails: any
) => {
	try {
		analytics.track('web_vitals_load', {
			url: pageProps.url,
			path: pageProps.path,
			referrer: pageProps.referrer,
			hostname: pageProps.hostname,
			id: eventDetails.id,
			name: eventDetails.name,
			delta: eventDetails.delta,
			deltaRounded: eventDetails.deltaRounded,
			entries: eventDetails.entries,
			navigationType: eventDetails.navigationType,
			rating: eventDetails.rating,
			value: eventDetails.value,
			valueRounded: eventDetails.valueRounded
		})
	} catch (error) {
		throw new Error(`Error tracking web vitals: ${error}`)
	}
}
