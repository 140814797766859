import { AnalyticsBrowser } from '@segment/analytics-next'

export const trackExperienceViewed = (
	analytics: AnalyticsBrowser,
	experimentDetails: any,
	url: string,
	path: string,
	referrer: string,
	hostname: string,
	anonymousId: string
) => {
	const { experience_id, experience_name, variant_name, type, evaluation, state } =
		experimentDetails

	analytics.track(
		'Experience Viewed',
		{
			url: url,
			path: path,
			referrer: referrer,
			hostname: hostname,
			experience_id: experience_id,
			experience_name: experience_name,
			variant_name: variant_name,
			type: type,
			evaluation: evaluation,
			source: 'marketing-websites',
			state: state
		},
		{
			anonymousId: anonymousId
		}
	)
}
