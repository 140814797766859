export function getLocalizedScriptId(locale: string | undefined) {
	let scriptId

	switch (locale) {
		case 'en-US':
			scriptId = 'd9ac540c-829e-4cba-982f-8931c2a8574d'
			break
		case 'en-AU':
			scriptId = '44d2ca30-6ae0-41d7-9ecb-088670d6f834'
			break
		case 'en-GB':
			scriptId = '48d5a480-a284-4782-aa0c-e1c17cec780c'
			break
		case 'fr-FR':
		case 'en-FR':
			scriptId = '8a407fae-18b0-469f-9a4a-5a1585455be1'
			break
		case 'nl-NL':
		case 'en-NL':
			scriptId = '74e0be6a-204f-4430-a14c-c5ca022fbaca'
			break
		case 'it-IT':
		case 'en-IT':
			scriptId = 'faa0d987-7cf8-4940-8c8c-198d22f7fceb'
			break
		case 'es-ES':
		case 'en-ES':
			scriptId = 'e5de84be-72bb-48bf-bb17-40f9aca41ebf'
			break
		case 'es-MX':
		case 'en-MX':
			scriptId = '5565a50c-cd1c-480f-8344-0aeac4be9122'
			break
		case 'de-DE':
		case 'en-DE':
			scriptId = '09804e83-7e5d-4ceb-83f7-4c5b76314e22'
			break
		case 'nb-NO':
		case 'no-NO':
		case 'en-NO':
			scriptId = '83f4e1bc-67f9-44d6-a2e9-175f2aaf5581'
			break
		case 'da-DK':
		case 'en-DK':
			scriptId = '52219dcb-b68e-46e4-879d-eb4ab53db7d1'
			break
		case 'sv-SE':
		case 'en-SE':
			scriptId = 'df249d93-0d8b-47c1-9f2f-ffcc0568b4ff'
			break
		case 'de-AT':
		case 'en-AT':
			scriptId = '9f7fc51c-749f-4051-8039-33714197b242'
			break
		case 'en-SG':
			scriptId = 'ee521f38-b891-4257-8993-c60cd239eb34'
			break
		default:
			scriptId = 'd9ac540c-829e-4cba-982f-8931c2a8574d'
			break
	}

	return process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ||
		process.env.VERCEL_ENV === 'production'
		? scriptId
		: `${scriptId}-test`
}
